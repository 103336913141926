import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import styled from 'styled-components';

import { trackEvent } from '../services/analytics';
import withOrderContext from '../withOrderContext';

import OrderContainer from '../pagesComponents/common/OrderContainer';
import {
  mobileThresholdPixels, colors, Wrapper, Block, PageTitle,
}
  from './order/styledComponents';
import android from '../assets/store.android.svg';
import iOS from '../assets/store.ios.svg';
import message from '../assets/message.svg';

const TextLink = styled.span`
  color: ${colors.navy};
  text-decoration: underline;
  cursor: pointer;
`;

const P = styled.p`
  color: ${colors.navy};
  text-align: center;
  padding: 20px;
  font-weight: 300;
  margin: 20px 0px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 10px 0px;
    font-size: 12px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -80px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: -40px;
    padding: 0px 25px;
  }
`;

const Image = styled.img``;
const Icon = styled.img`
  margin: 10px;
`;

const WhiteBlock = styled.div`
  background-color: ${colors.white};
  margin-top: 70px;
  margin-bottom: 50px;
  padding: 30px 180px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 20px;
    margin: 20px;
  }
`;

const Text1 = styled(P)`
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  padding: 20px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 20px;
  }
`;

const Text2 = styled(P)`
  font-size: 14px;
  line-height: 16px;
  padding: 0px 20px 50px;
`;

const Row = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

const FootNoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FootNote = styled.span`
  font-family: Libre Baskerville;
  font-size: ${({ small }) => small ? '18px' : '22px'};
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: ${colors.gold};
`;

function reinitOrder(trackingOriginElement, reinit) {
  trackEvent('click', trackingOriginElement);
  reinit();
}

const Success = ({ orderContext: { reinit, rdv1Location } }) => (
  <OrderContainer>
    <Wrapper>
      <Block>
        <PageTitle>
          {rdv1Location === 'atShop'
            ? 'Votre demande a bien été enregistrée'
            : 'La commande de votre client a bien été enregistrée'}
        </PageTitle>

        <TextContainer>
          {rdv1Location === 'atShop' ? null : (
            <P>
              On s’occupe de tout ! Pas la peine d’encaisser les retouches des clients ;)
            </P>
          )}
        </TextContainer>

        <WhiteBlock>
          <Row>
            <Image src={message} />
          </Row>
          <Text1>
            {rdv1Location === 'atShop'
              ? (
                <>
                  Nous sommes à la recherche de votre couturier.
                  <br />
                  Vous allez recevoir un email de confirmation dès l’affiliation de l’un de nos couturier.
                  <br />
                  <br />
                  On s’occupe d’informer votre client tout au long du process.
                </>
              )
              : `Un e-mail pour confirmer la bonne réception de la demande du client lui est
            directement envoyé après validation.`}
          </Text1>
          <Text2>
            {rdv1Location === 'atShop'
              ? ''
              : 'Pour suivre sa commande ou parrainer ses amis, votre client peut se connecter sur notre application :'}
          </Text2>
          {rdv1Location === 'atShop'
            ? (
              <FootNoteContainer>
                <FootNote>Ajusté ou réparé</FootNote>
                <FootNote small>dans votre ville</FootNote>
              </FootNoteContainer>
            ) : (
              <Row>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://play.google.com/store/apps/details?id=fr.tilli.app"
                  onClick={() => trackEvent('click', 'store_android_success')}
                >
                  <Icon src={android} alt="application Android" />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://itunes.apple.com/us/app/tilli/id1280578121"
                  onClick={() => trackEvent('click', 'store_iOS_success')}
                >
                  <Icon src={iOS} alt="application iPhone" />
                </a>
              </Row>
            )}
        </WhiteBlock>

        <Link
          to="/"
          style={{ flex: 1, textAlign: 'center' }}
          onClick={() => reinitOrder('home_success', reinit)}
        >
          <TextLink>retour à l‘accueil</TextLink>
        </Link>
      </Block>
    </Wrapper>
  </OrderContainer>
);

Success.propTypes = {
  orderContext: PropTypes.shape({
    reinit: PropTypes.func.isRequired,
    rdv1Location: PropTypes.string,
  }).isRequired,
};

export default withOrderContext(Success);
